import '@toptoon-developers/global.toptoonplus.common.lib/dist/utils/ExtString';
import 'swiper/css';
import 'swiper/css/navigation';
import '../styles/globals.css';

import { LoadingComponent } from '@/presenter/common/loading';
import { DefaultSeo } from 'next-seo';
import { ThemeProvider } from 'next-themes';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { RecoilRoot } from 'recoil';

const RootContainer = dynamic(
  () => import('@/presenter/container/RootContainer'),
  {
    ssr: false,
    suspense: true,
    loading: () => <LoadingComponent />,
  },
);

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, shrink-to-fit=no, user-scalable=0"
        />
      </Head>
      <RecoilRoot>
        <ThemeProvider
          enableSystem={false} // prefers-color-scheme 사용여부
          attribute="class"
        >
          <DefaultSeo
            title="TOPTOON｜Web漫画をフルカラーで読めるサイト！恋愛・ファンタジー・ドラマ・アクション・ミステリーなどの全ジャンルの漫画作品が毎日連載されます！"
            description="TOPTOON｜Web漫画をフルカラーで読めるサイト！恋愛・ファンタジー・ドラマ・アクション・ミステリーなどの全ジャンルの漫画作品が毎日連載されます！「秘密の授業」、「ハーレムライフ」、「工学ヘヴン」など、オリジナル作品・オリジナルIPを持つ漫画プラットフォーム"
            openGraph={{
              title: 'toptoon.jp',
              description:
                'TOPTOON｜Web漫画をフルカラーで読めるサイト！恋愛・ファンタジー・ドラマ・アクション・ミステリーなどの全ジャンルの漫画作品が毎日連載されます！「秘密の授業」、「ハーレムライフ」、「工学ヘヴン」など、オリジナル作品・オリジナルIPを持つ漫画プラットフォーム',
            }}
          />
          <RootContainer>
            <Component {...pageProps} />
          </RootContainer>
        </ThemeProvider>
      </RecoilRoot>
    </>
  );
}

export default MyApp;
